<template>
  <div class="cabinet-wrapper">
    <Header />
    <div class="content">
      <div class="cabinet__row">
        <div class="cabinet__aside pt-12">
          <CabinetAside />
          <MaterialsFilter v-if="$route.name === 'MaterialsDetail' || $route.name === 'Materials'"/>
        </div>
        <router-view />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "../components/main/Header.vue";
import Footer from "../components/Footer.vue";
import CabinetAside from "@/components/pageComponents/cabinet/CabinetAside.vue";
import MaterialsFilter from "@/components/pageComponents/cabinet/MaterialsFilter.vue";
import { mapActions, mapGetters } from "vuex";
import { bus } from "@/main"
export default {
  components: { Header, Footer, CabinetAside, MaterialsFilter },
  name: "cabinet",
  computed: {
   ...mapGetters(["user", "pageVerify", "Nosologys"]),
  },
  methods: {
    ...mapActions([
      "getSession",
      "fetchPageVerify",
      "getSession",
      "fetchNosologys",
      "logout",
    ]),
  },
  async mounted() {
     bus.$emit("scrollLock", false);
    await this.fetchPageVerify(this.$route.path);
    if (!Object.keys(this.user).length) {
      await this.getSession();
    }
    if (!Object.keys(this.Nosologys).length) {
      await this.fetchNosologys();
    }
  },
  watch: {
    async $route(to, from) {
      if (to.path !== from.path) {
        await this.fetchPageVerify(this.$route.path);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cabinet-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  min-height: 100vh;
  height: 100%;
  position: relative;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 50vw;
    height: 100vh;
    background-color: #fff;
    content: "";
    display: block;
    @media screen and (max-width: 1220px) {
      display: none;
    }
  }

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    width: 50vw;
    height: 100%;
    background-color: #f8f8f8;
    content: "";
    display: block;
    @media screen and (max-width: 1220px) {
      display: none;
    }
  }
}

.cabinet__row {
  display: grid;
  grid-template-columns: 280px 1fr;
  width: 100%;
  @media screen and (max-width: 1220px) {
    display: block;
  }
  @media screen and (max-width: 767px) {
  }
}

.footer {
  z-index: 100;
}

.header {
  z-index: 10001;
}

.content {
  z-index: 1000;
  min-height: 100%;
  flex-grow: 1;
  display: flex;
  align-items: stretch;
  @media screen and (max-width: 1220px) {
    background-color: #f8f8f8;
    z-index: auto;
  }
}

.header {
  background-color: #fff;
}

.cabinet__aside {
  padding-right: 24px;
  position: sticky;
  top: 15px;
  overflow-y: auto;
  max-height: 100vh;
  -ms-overflow-style: scrollbar;
    scrollbar-color: #d8dada transparent;
    scrollbar-width: thin;
    scroll-margin-right: 3px;
    scroll-padding-right: 3px;

    &::-webkit-scrollbar {
      width: 5px;
      height: 8px;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #d8dada;
      border-radius: 5px;
      height: 8px;
    }

    &::-webkit-scrollbar--track-piece {
      border-radius: 5px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-track {
      border-radius: 5px;
      background-color: #d8dada;
      border: 3px solid #fff;
    }

    &::-moz-scrollbar-button:decrement,
    &::-moz-scrollbar-button:increment,
    &::-moz-scrollbar-button:vertical:start:decrement,
    &::-moz-scrollbar-button:vertical:end:increment,
    &::-moz-scrollbar-button:horizontal:start:decrement,
    &::-moz-scrollbar-button:horizontal:end:increment,
    &::-webkit-scrollbar-button:vertical:start:decrement,
    &::-webkit-scrollbar-button:vertical:end:increment,
    &::-webkit-scrollbar-button:horizontal:start:decrement,
    &::-webkit-scrollbar-button:horizontal:end:increment {
      width: 0px !important;
      height: 0px !important;
    }
  @media screen and (max-width: 1220px) {
    display: none;
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
}
</style>

<style>
.cabinet__row .material__title {
  -webkit-line-clamp: 4;
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

/* .cabinet-wrapper .material__title {
  font-size: 18px !important;
  line-height: 24px !important;
} */

.cabinet-wrapper .material__image-container {
  height: 150px !important;
}

.cabinet-wrapper .material__description {
  display: none !important;
}

.cabinet-wrapper .material__category {
  font-size: 12px !important;
}
</style>